function Header() {
    return (
      <>
      <div className="logo-box">
        <div className="top_fix">
          <span className="logo">
            <img src="/img/logo.png" alt="Latur Police" title="Latur Police"/>
          </span>
          <h3 className="top_bar" title="Latur Police" style={{marginTop:'5px!important'}}><b>Latur Police</b></h3>
        </div>

        <h5 className="heading">E-Service Sheet</h5>
      </div>        
      </>
    );
  }
  
  export default Header;
  