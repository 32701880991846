import './Clients.css';

function Clients() {
    return (
        <section className={'clients'}>
            <div id="infinite" className={'highwaySlider'}>
                <div className={'highwayBarrier'}>
                    <ul className={'highwayLane'}>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/1.png' alt='1' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/2.png' alt='2' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/3.png' alt='3' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/4.png' alt='4' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/5.png' alt='5' /></span></li>

                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/1.png' alt='1' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/2.png' alt='2' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/3.png' alt='3' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/4.png' alt='4' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/5.png' alt='5' /></span></li> 

                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/1.png' alt='1' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/2.png' alt='2' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/3.png' alt='3' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/4.png' alt='4' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/5.png' alt='5' /></span></li>

                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/1.png' alt='1' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/2.png' alt='2' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/3.png' alt='3' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/4.png' alt='4' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/5.png' alt='5' /></span></li>              
                    </ul>
                </div>
            </div>
        </section>
    );
  }
  
  export default Clients;
  


