import React, {useEffect} from 'react';
import { useNavigate} from 'react-router-dom';

function Logout() {
    const nav = useNavigate();
    useEffect( ()=> {
        logout();
    });
    const logout = () => {
        localStorage.clear('token');
        localStorage.clear('sevarth_id');
        window.location.href = '/';
    }
    return (<> </>)
}

export default Logout;
