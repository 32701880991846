import React, {useEffect, useState} from "react";
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import {useNavigate} from "react-router-dom";
function Login() {
    const [isLoading, setIsLoading] = useState(false);
    const [loginMessage, setloginMessage] = useState('');
    const [captchaImage, setCaptchaImage] = useState('');
    const [captchaEncryptCode, setCaptchaEncryptCode] = useState('');
    const [loginInputValues, setLoginInputValues] = useState({
        sevarthId : "",
        password : "",
        captcha: ""
    });
    const [validation, setValidation] = useState({
        sevarthId : "",
        password : "",
        captcha : ""
    });
    useEffect(() => {
        captcha();
    }, []);
    const nav = useNavigate();
    const handleLoginChange = (e) => {
        const {name, value} = e.target;
        setLoginInputValues({...loginInputValues, [name]: value});
    }
    const checkLoginValidation = () => {
      let errors = JSON.parse(JSON.stringify(validation));

        // sevarthId validation
        if (!loginInputValues.sevarthId) {
          errors.sevarthId = "Sevarth Id is required";
        } else {
          errors.sevarthId = "";
        }

        //password validation
        const password = loginInputValues.password;
        if (!password) {
          errors.password = "Password is required";
        }
        else {
          errors.password = "";
        }

        //captcha validation
        if (!loginInputValues.captcha) {
          errors.captcha = "Captcha is required";
        } else {
          errors.captcha = "";
        }

        setValidation(errors);
        if(errors.sevarthId === '' && errors.captcha === '' && errors.password === ''){
          return true;
        }else{
          return false;
        }
    };
    // function onChange(value) {
    //     setCaptchavalue(value);
    // }

  const login = (page) => {
    const isSuccess = checkLoginValidation();
    if(isSuccess) {

    setIsLoading(true);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const data = new FormData();
    data.append('sevarth_id', loginInputValues.sevarthId);
    data.append('password', loginInputValues.password);
    data.append('code', captchaEncryptCode);
    data.append('captcha', loginInputValues.captcha);
    axios.post(`${appConstant.API_URL}/user/login`, data, params)
      .then(res => {
        setIsLoading(false);
        if(res?.data?.status === 400){
            setloginMessage(res?.data?.message);
        }else{
            localStorage.setItem('token', res?.data?.data?.token);
            localStorage.setItem('sevarth_id', loginInputValues.sevarthId);
            nav('/varify', {state:{sevarthId: loginInputValues.sevarthId}});
        }
        // setUsers(res?.data?.data);
      })
      .catch(error => {
        setIsLoading(false);
      });
    }
  }
  function captcha() {
      const params = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
      }
    axios.get(`${appConstant.API_URL}/user/captcha`, params)
        .then(res => {
            if(res?.data?.status === 200){
                setCaptchaImage(res?.data?.data?.captcha);
                setCaptchaEncryptCode(res?.data?.data?.code);
            }
        })
        .catch(error => {
        });
  }
  return (
    <>
      <Header/>
      <div className="content">
        <div className="form-box">
          <h3 style={{marginBottom : '20px'}}>Login</h3>
            {loginMessage && <p className='formErrors' style={{color: 'red'}}>{loginMessage}</p>}
          {/*<select className="inputs" name="login">*/}
          {/*  <option value='1'>Please select user type</option>*/}
          {/*</select>*/}
          <label className="label">Severth ID</label>
          <input type='text' className="inputs" name='sevarthId' value={loginInputValues.sevarthId} onChange={(e) => handleLoginChange(e)} placeholder="Enter Sevarth Id" />
          {validation.sevarthId && <p className='formErrors'>{validation.sevarthId}</p>}
          <label className="label">Password</label>
          <input type='password' className="inputs" name='password' value={loginInputValues.password} onChange={(e) => handleLoginChange(e)} placeholder="Enter Password" />
          {validation.password && <p className='formErrors'>{validation.password}</p>}
            {/* <div style={{display: 'flex',justifyContent:'center',marginBottom:'10px'}}>
                <ReCAPTCHA
                    sitekey="6LdQM0ofAAAAAG_C-otQT2qOYMg4qiLS3obTmdd8"
                    onChange={onChange}
                />
            </div> */}
          {/* {validation.captcha && <p className='formErrors'>{validation.captcha}</p>} */}
          {/*<label className="label">Enter Captcha</label>*/}
          {/*<input type='text' className="inputs" placeholder="Enter Captcha" />*/}

          <label className="label">Captcha</label>
          <div className="captcha" >
              <div dangerouslySetInnerHTML={{__html: captchaImage}}></div>
            {/*<img className="captcha-img" src='img/captcha.png' alt='captcha'/>*/}
            <input className="inputs" type='text' value={loginInputValues.captcha} onChange={(e) => handleLoginChange(e)} name='captcha' />
          </div>
            {validation.captcha && <p className='formErrors'>{validation.captcha}</p>}
          <div className="flex-box">
            <button className="gray-btn" onClick={() => {captcha()}}>Refresh captcha</button>
            <button className="blue-btn"   onClick={() => {login()}}>Submit{isLoading ? <img src="img/loader.gif" alt="loader" style={{marginLeft:'10px',width:'10px',}}/> : '' }</button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Login;
