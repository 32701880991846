import React, { useState } from "react";
import Cropper from "react-easy-crop";

function ImageCropper({ image, onCropDone, onCropCancel }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1 / 1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onAspectRatioChange = (event) => {
    setAspectRatio(event.target.value);
  };

  return (
    <div className="cropper">
        <Cropper
        image={image}
        aspect={aspectRatio}
        crop={crop}
        zoom={zoom}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
        style={{
            containerStyle: {
            width: "100%",
            height: "80%",
            backgroundColor: "#fff",
            },
        }}
        />

        <div className="action-btns">
            <button className="gray-btn" onClick={onCropCancel} style={{marginRight: '15px'}}> Cancel </button>
            <button className="blue-btn" onClick={() => {onCropDone(croppedArea);}}>Done</button>
        </div>
    </div>
  );
}

export default ImageCropper;
