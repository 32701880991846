import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import { Link } from 'react-router-dom';
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";
function Dashboard() {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    userdata();
    listFeedback();
    if(!localStorage.getItem('token')){
      window.location.href = '/logout';
    }
  }, []);
  const showModal = (e) => {
    e.preventDefault();
    setModal(true);
  }

  // image crop
  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");

  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
          imageObj1,
          imgCroppedArea.x,
          imgCroppedArea.y,
          imgCroppedArea.width,
          imgCroppedArea.height,
          0,
          0,
          imgCroppedArea.width,
          imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");

      setImgAfterCrop(dataURL);
      uploadPhoto(dataURL);
      setCurrentPage("img-cropped");
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setImage("");
  };

// image crop end
  const [users, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [defaultTab, setDefaultTab] = useState(7);
  const [loginMessage, setloginMessage] = useState('');
  const [feedbacks, setFeedbacks] = useState([]);
  const userdata = (page) => {
    setIsLoading(true);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const data = new FormData();
    data.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
    axios.post(`${appConstant.API_URL}/user/index`, data, params)
        .then(res => {
          setIsLoading(false);
          setUsers(res?.data?.data);
          if(res?.data?.data?.user_photo){
            setImgAfterCrop(res?.data?.data?.user_photo);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
  }
  const [feedback, setFeedback] = useState({
    message : ""
  });
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFeedback({...feedback, [name]: value});
  }
  const [validation, setValidation] = useState({
    message : "",
    captcha : ""
  });
  // function changeCapcha(value) {
  //   setCaptchavalue(value);
  // }
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));
    //message validation
    if (!feedback.message) {
      errors.message = "Message is required";
      }else {
      errors.message = "";
    }
    setValidation(errors);
    if(errors.message === ''){
      return true;
    }else{
      return false;
    }
};
const submit = (e) => {
    e.preventDefault();
    const isSuccess = checkValidation();
    if(isSuccess) {
      insertFeedback();
    }
  }
const insertFeedback = (page) => {
    setIsLoading(true);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const data = new FormData();
    data.append('token', localStorage.getItem('token') );
    data.append('message', feedback.message );
    axios.post(`${appConstant.API_URL}/user/feedback`, data, params)
      .then(res => {
        setIsLoading(false);
        setloginMessage(res?.data?.message);
        listFeedback();
      })
      .catch(error => {
        setIsLoading(false);
      });
}

  const listFeedback = () => {
    setIsLoading(true);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const data = new FormData();
    data.append('token', localStorage.getItem('token') );
    axios.post(`${appConstant.API_URL}/user/list_feedback`, data, params)
        .then(res => {
          setIsLoading(false);
          setFeedbacks(res?.data?.data);
        })
        .catch(error => {
          setIsLoading(false);
        });
  }
  const uploadPhoto = (file) => {
    setIsLoading(true);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const data = new FormData();
    data.append('token', localStorage.getItem('token') );
    data.append('user_photo', file );
    axios.post(`${appConstant.API_URL}/user/upload_photo`, data, params)
        .then(res => {
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
  }
  const changeTab = (val) => {
    setDefaultTab(val);
  }
  return (
    <>
      <Header/>
      <div className="content">
        <div className="logout-box">
          <div className='relative'>
            <img className="profile" src={imgAfterCrop ? imgAfterCrop : '/clients/4.png'} alt='logo' />
            <img className="edit" src='/img/edit.png' alt='logo' onClick={showModal}/>
          </div>
          <h6 className="title">{users?.name}</h6>
          {/*<h6 className="designation">Constable</h6>*/}
          <h6 className="number">{localStorage.getItem('sevarth_id')}</h6>
          <Link to='/logout' className="blue-btn">Logout</Link>
        </div>
        <div className="dashboard">
          <div className="tabset">
            <input type="radio" name="tabset" id="tab7" checked={defaultTab === 7} onChange={() => {changeTab(7)}}  aria-controls="document"/>
            <label htmlFor="tab7">Document</label>
            {/* tab 8 */}
            <input type="radio" name="tabset" id="tab8" checked={defaultTab === 8} onChange={() => {changeTab(8)}}  aria-controls="feedback"/>
            <label htmlFor="tab8">Feedback</label>

            <div className="tab-panels">
              <section id="document" className="tab-panel">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Title</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {users?.document_file ?
                        (<><td>{users?.document_date}</td>
                        <td>{users?.document_title}</td>
                        <td><a href={users?.document_file} target={'_blank'}  rel="noreferrer" download='document_file'><img className="icon" src='/img/pdf.png' alt='pdf' /></a> </td> </>)
                        :
                        (<td colSpan={3}>Not Found.</td>)
                      }
                    </tr>
                  </tbody>
                </table>
              </section>
              <section id="feedback" className="tab-panel">
                {loginMessage ? (<h4 style={{color: 'green'}}>{loginMessage}</h4>) : (
                  <>
                   <textarea className="inputs"  rows="3" name='message'  placeholder="Please enter any query or feedback here." value={feedback.message} onChange={(e) => handleChange(e)}></textarea>
                {validation.message && <p className='formErrors'>{validation.message}</p>}
                <button onClick={submit} className="blue-btn">Submit {isLoading ? <img src="img/loader.gif" alt="loader" style={{marginLeft:'10px',width:'10px',}}/> : '' }</button>
                    {feedbacks?.length > 0 && feedbacks?.map((result, i) =>
                        <div className='feedback-response' key={'feedback' + i}>
                          <h3 className='query-title highlight-gray'>Your Query: <small>{result?.message}</small></h3>
                          {result?.admin_response && <h3 className='query-response highlight-green'>Response: <small>{result?.admin_response}</small></h3>}
                          {result?.admin_response === '' && <i>Please wait for the response</i>}
                        </div>
                    )}
                  </>
                )}

              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
      {modal ?
          <div className='modal'>
            <div className='modal-content'>
              {currentPage === "choose-img" ? (
                  <FileInput setImage={setImage} onImageSelected={onImageSelected} />
              ) : currentPage === "crop-img" ? (
                  <ImageCropper
                      image={image}
                      onCropDone={onCropDone}
                      onCropCancel={onCropCancel}
                  />
              ) : ( setModal(false))}
            </div>
          </div>
          : ''}
    </>
  );
}

export default Dashboard;
