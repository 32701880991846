import {useState} from "react";
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import {useNavigate} from "react-router-dom";
function ChangePwd() {
    const [isLoading, setIsLoading] = useState(false);
    const [captchaValue, setCaptchavalue] = useState('');
    const [message, setMessage] = useState('');
    const [changePwdValues, setchangePwdValues] = useState({
        newPassword : "",
        confirmPassword : ""
    });
    const [validation, setValidation] = useState({
        newPassword : "",
        confirmPassword : ""
    });
    const nav = useNavigate();
    const handleChange = (e) => {
        const {name, value} = e.target;
        setchangePwdValues({...changePwdValues, [name]: value});
    }
    const checkValidation = () => {
      let errors = JSON.parse(JSON.stringify(validation));

        //password validation
        const password = changePwdValues.newPassword;
        if (!password) {
          errors.newPassword = "Password is required";
        }
        else {
          errors.newPassword = "";
        }

        //confirm password validation
        const cpassword = changePwdValues.confirmPassword;
        if (!cpassword) {
        errors.confirmPassword = 'Repeat Password';
        } else if (cpassword !== password) {
        errors.confirmPassword = 'Both password should match';
        } else {
        errors.confirmPassword = '';
        }

        setValidation(errors);
        if(errors.newPassword === '' && errors.confirmPassword === ''){
          return true;
        }else{
          return false;
        }
    };
    function onChange(value) {
        setCaptchavalue(value);
    }

  const updatePwd = (page) => {
    const isSuccess = checkValidation();
    if(isSuccess) {

    setIsLoading(true);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const data = new FormData();
    data.append('password', changePwdValues.newPassword );
    data.append('token', localStorage.getItem('token'));
    axios.post(`${appConstant.API_URL}/user/update_password`, data, params)
      .then(res => {
        setIsLoading(false);
        if(res?.data?.status === 400){
          setMessage(res?.data?.message);
        }else{
            localStorage.setItem('token', res?.data?.data?.token);
            window.location.href = '/dashboard';
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
    }
  }
  return (
    <>
      <Header/>
      <div className="content">
        <div className="form-box">
          <h3 style={{marginBottom : '20px'}}>Change Password</h3>
         
          {message && <p className='formErrors' style={{color: 'red'}}>{message}</p>}
          <label className="label">New Password</label>
          <input type='password' className="inputs" name='newPassword' value={changePwdValues.newPassword} onChange={(e) => handleChange(e)} placeholder="Enter New Password" />
          {validation.newPassword && <p className='formErrors'>{validation.newPassword}</p>}

          <label className="label">Confirm Password</label>
          <input type='password' className="inputs" name='confirmPassword' value={changePwdValues.confirmPassword} onChange={(e) => handleChange(e)} placeholder="Enter Confirm Password" />
          {validation.confirmPassword && <p className='formErrors'>{validation.confirmPassword}</p>}
          <div className="flex-box">
            <button className="gray-btn">Reset</button>
            <button className="blue-btn"   onClick={() => {updatePwd()}}>Submit{isLoading ? <img src="img/loader.gif" alt="loader" style={{marginLeft:'10px',width:'10px',}}/> : '' }</button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default ChangePwd;
